
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import UserManagementService from "@/services/user-management"
import PopUp from "@/components/PopUp.vue";
import utils from "@/utils/utils"
@Component({
    components: {
        Header, PopUp
    },
})
export default class Assets extends Vue {
    error: any = "";
    old_password = '';
    new_password = '';
    confirm_password = '';
    showPassword = false;
    showPasswordNew = false;
    showPasswordConfirm = false;
    public passwordObject: any = {}
    // For Modal
    modalShow = false;
    modalMessage: string[] = [];
    modalSimpleMessage: string = "";
    modalType = "";
    closeModal() {
        this.modalShow = false;
        this.modalMessage = [];
        this.modalType = "";
        this.modalSimpleMessage = ""
    }
    showModal(message: string, array: string[], type: "success" | "error" | "errorArray",) {
        this.modalShow = true;
        this.modalMessage = array;
        this.modalType = type;
        this.modalSimpleMessage = message
    }
    // modal end
    togglePasswordVisibility() {
        this.showPassword = !this.showPassword;
    }
    togglePasswordVisibilityNew() {
        this.showPasswordNew = !this.showPasswordNew;
    }
    togglePasswordVisibilityConfirm() {
        this.showPasswordConfirm = !this.showPasswordConfirm;
    }
    change_Password() {
        const error_list = utils.getEmptyKeys(this.passwordObject, ['old_password', 'new_password'])
        if (error_list.length > 0 || this.confirm_password === "") {
            this.error = utils.generateErrorMessage(error_list.concat(this.confirm_password === "" ? 'Confirm Password' : ""))
        }
        else {
            const passwordObject: any = {
                old_password: this.passwordObject.old_password,
                new_password: this.passwordObject.new_password,
            }
            if (this.confirm_password === this.passwordObject.new_password) {
                UserManagementService.changePassword(passwordObject)
                    .then((res) => {
                        this.reset_Password()
                        // if (res.data.detail === "Password updated successfully") {
                        this.showModal(res.data.detail, [], "success");
                        // }
                        // else if (res.data.detail === "Incorrect old password.") {
                        // this.showModal(res.data.detail, [], "error");
                        // }
                    })
                    .catch((e) => {
                        console.log('Unexpected error:', e);
                        this.showModal('An unexpected error occurred', [], "error");
                    });
            }
            else {
                // this.showModal("Password Not Matched", [], "error")
                this.error = "Password Not Matched";
            }
        }
    }
    reset_Password() {
        this.passwordObject = {
            old_password: '',
            new_password: ''
        }
        this.confirm_password = '';
        this.showPassword = false;
        this.error = "";
    }
}
