import { render, staticRenderFns } from "./reset-password.vue?vue&type=template&id=30878f76&scoped=true"
import script from "./reset-password.vue?vue&type=script&lang=ts"
export * from "./reset-password.vue?vue&type=script&lang=ts"
import style0 from "./reset-password.vue?vue&type=style&index=0&id=30878f76&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30878f76",
  null
  
)

export default component.exports